import { Navigate } from "react-router";

export const AuthorizeUser = ({ children }) => {
  const storedData = JSON.parse(localStorage.getItem("token"));

  if (!storedData || !storedData.token || storedData.role !== "admin") {
    return <Navigate to={"/"} replace={true} />;
  } else if (storedData.role !== "admin") {
  }

  return children;
};
