import { React, useState } from "react";
import Sidebar from "../components/Sidebar";
import { BsPeople } from "react-icons/bs";
import { GrMoney } from "react-icons/gr";
import { IoHelpOutline } from "react-icons/io5";
import { TbLayoutDashboard } from "react-icons/tb";
import { TbPackages } from "react-icons/tb";
import ClientDashboardTable from "../components/ClientDashboardTable";
import { useParams } from "react-router";
import { getAllUser, getUser } from "../helpers/helper";
import { useQuery } from "react-query";
import toast, { Toaster } from "react-hot-toast";

function ClientDashboard() {
  const { email } = useParams();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const { isError, data: User } = useQuery({
    queryKey: ["UserData"],
    queryFn: () => getUser(email), // Pass the function reference without calling it
  });

  const { data: Users } = useQuery({
    queryKey: ["UsersData"],
    queryFn: getAllUser, // Pass the function reference without calling it
  });

  const paymentColumnData = [
    { date: "Date", type: "Type", status: "Status", amount: "Amount" },
  ];

  const Menus = [
    {
      title: "Dashboard",
      icon: <TbLayoutDashboard />,
      address: "dashboard/user/:email",
    },
    { title: "Product", icon: <TbPackages />, address: "dashboard/user/:email/userProduct" },
    { title: "Help", icon: <IoHelpOutline />, address: "dashboard/user/:email" },
  ];

  return (
    <div>
      {isError && toast.error("An error occurred")}
      <Toaster position="top-center"></Toaster>
      <Sidebar open={sidebarOpen} setOpen={setSidebarOpen} data={Menus} />
      <div className={`${sidebarOpen ? "w-48" : "w-16"} bg-slate-200`}></div>
      <div className="bg-slate-200 w-full min-h-screen">
        <div className="bg-slate-200 w-10/12 max-sm:w-10/12 max-sm:ml-16 mx-auto pb-10">
          <p className="text-xl text-black pt-8  max-md:ml-12 max-sm:ml-2">
            Welcome {User ? User.data.email : "Loading"}
          </p>

          <div className="max-md:bg-white max-md:py-5 w-full rounded-lg max-md:w-11/12 max-md:ml-12 max-sm:ml-2 max-sm:mt-5 mb-10 max-md:mb-1 mt-10 flex flex-wrap items-center justify-around ">
            <div className="w-3/12 max-md:w-5/12 bg-white max-md:mb-5 py-6 max-md:py-3 md:rounded-md">
              <div className="mt-0 leading-3 text-center">
                <p className="text-2xl mb-2  font-bold text-gray-400 text-center">
                  Mail
                </p>
                <p className="text-xs text-center">
                  {User ? User.data.email : "Loading"}
                </p>
              </div>
            </div>
            <div className="w-3/12 max-md:w-5/12 bg-white max-md:mb-5 py-6  max-md:py-3 md:border-x-2 md:rounded-md">
              <div className="mt-0 leading-3 text-center">
                <p className="text-2xl mb-2  font-bold text-gray-400">
                  Contact
                </p>
                <p className="text-sm ">
                  {User ? User.data.phoneNumber : "Loading"}
                </p>
              </div>
            </div>
            <div className="w-3/12 max-md:w-5/12 bg-white py-6 max-md:py-3 md:border-x-2  md:rounded-md">
              <div className="mt-0 leading-3 text-center">
                <p className="text-2xl mb-2  font-bold text-gray-400">
                  Deposit
                </p>
                <p className="text-sm">
                  {User ? User.data.deposit : "Loading..."}
                </p>
              </div>
            </div>
            <div className="w-3/12 max-md:w-5/12 bg-white last:py-6 max-md:py-3 rounded-md">
              <div className="mt-0 leading-3 text-center">
                <p className="text-2xl mb-2  font-bold text-gray-400">Status</p>
                <p className="text-sm text-center">
                  {User ? User.data.status : "Loading"}
                </p>
              </div>
            </div>
          </div>

          <div className="md:bg-white w-full py-10 rounded-lg max-md:w-11/12 max-md:ml-12 max-sm:ml-2 my-10 max-md:my-1 flex flex-wrap items-center justify-evenly">
            <div className="flex w-3/12 max-md:w-full bg-white max-md:mb-5 md:justify-center max-md:px-6 max-md:py-3  justify-between rounded-md md:border-r-1">
              <div className="bg-c-4 px-4 py-4 rounded-full mr-3 max-sm:mt-2">
                <GrMoney className="sm:text-4xl  text-c-1 " />
              </div>
              <div className="mt-0 leading-3">
                <p className="text-xs text-gray-400">Profit Margin</p>
                <p className="text-xl font-bold text-right">
                  {User ? User.data.total : "Loading..."}
                </p>
              </div>
            </div>
            <div className="flex w-3/12 max-md:w-full bg-white max-md:mb-5 md:justify-center max-md:px-6 max-md:py-3  justify-between rounded-md md:border-r-1">
              <div className="bg-c-4 px-4 py-4 rounded-full mr-3 max-sm:mt-2">
                <GrMoney className="sm:text-4xl  text-c-1 " />
              </div>
              <div className="mt-0 leading-3">
                <p className="text-xs text-gray-400">Total</p>
                <p className="text-xl font-bold text-right">
                  {User ? User.data.total + User.data.deposit : "Loading..."}
                </p>
              </div>
            </div>
            <div className="flex w-3/12 max-md:w-full bg-white max-md:mb-5 md:justify-center max-md:px-6 max-md:py-3 justify-between rounded-md md:border-l-1">
              <div className="bg-c-4 px-4 py-4 rounded-full mr-3 max-sm:mt-2">
                <BsPeople className="sm:text-4xl text-c-1 " />
              </div>
              <div className="mt-0 leading-3">
                <p className="text-xs text-gray-400">Total Customers</p>
                <p className="text-xl font-bold text-right">
                  {Users ? Users.length + 1 : "Loading..."}
                </p>
              </div>
            </div>
          </div>
          <div className=" w-full">
            <div className="bg-white w-full rounded-lg max-md:w-11/12 max-md:ml-12 max-sm:ml-2 pt-10 pb-0 overflow-auto scrollbar-hide col-span-2">
              <ClientDashboardTable
                rowData={User ? User.data.transaction : []}
                columnData={paymentColumnData}
                tableHeader={"Payment History"}
                rowscount={10}
                diff={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientDashboard;
