
// import React, { useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import { IoIosArrowRoundBack } from "react-icons/io";
// import { TbLayoutDashboard } from "react-icons/tb";
// import { IoLogOut } from "react-icons/io5";
// import { IoMdClose } from "react-icons/io";
// import { Dialog, DialogContent} from "@mui/material";

// function Sidebar(props) {
//   const Menus = props.data;
//   const [selectedMenuItem, setSelectedMenuItem] = useState(null);
//   const [isHelpModalOpen, setHelpModalOpen] = useState(false);

//   const openHelpModal = () => {
//     setHelpModalOpen(true);
//   };

//   const [formData, setFormData] = useState({
//     userHelpMessage: '',
//     userEmail: ''
//   });

//   const closeHelpModal = () => {
//     setHelpModalOpen(false);
//   };

//   const showComingSoonAlert = () => {
//     alert("This service is coming soon!");
//   };

//   // Logout handler function
//   const navigate = useNavigate();
//   function userLogout() {
//     localStorage.removeItem("token");
//     navigate("/");
//   }

//   const FORM_ENDPOINT = "https://public.herotofu.com/v1/e1f49330-e058-11ee-8980-3397320e035b";
//     const [submitted, setSubmitted] = useState(false);
//     const handleSubmit = (e) => {
//     e.preventDefault();

//     const inputs = e.target.elements;
//     const data = {};

//     for (let i = 0; i < inputs.length; i++) {
//         if (inputs[i].name) {
//         data[inputs[i].name] = inputs[i].value;
//         }
//     }

//     fetch(FORM_ENDPOINT, {
//         method: 'POST',
//         headers: {
//         Accept: 'application/json',
//         'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(data),
//     })
//         .then((response) => {
//         if (!response.ok) {
//             throw new Error('Form response was not ok');
//         }

//         setSubmitted(true);
//         })
//         .catch((err) => {
//         // Submit the form manually
//         e.target.submit();
//         });
//     };

//   return (
//     <div className="flex fixed">
//       <div
//         className={`bg-white h-screen p-2 pt-8 ${
//           props.open ? "w-48" : "w-16"
//         } duration-300 relative max-sm:w-12`}
//       >
//         <IoIosArrowRoundBack
//           className={`bg-white text-black text-2xl rounded-full absolute -right-3 top-9 border border-black cursor-pointer ${
//             !props.open && "rotate-180"
//           } max-sm:hidden`}
//           onClick={() => props.setOpen(!props.open)}
//         />
//         <div className="inline-flex">
//           <TbLayoutDashboard className="text-black text-4xl rounded cursor-pointer block float-left mr-2" />
//           <h1
//             className={`text-black origin-left font-medium text-2xl ${
//               !props.open && "scale-0"
//             }`}
//           >
//             Dashboard
//           </h1>
//         </div>
//         <ul className="pt-2">
//           {Menus &&
//             Menus.map((menu, index) => (
//               <li
//                 key={index}
//                 className="text-gray-600 text-sm flex items-center gap-x-4 cursor-pointer p-2 ho duration-400 transition ease-in rounded-md mt-2"
//                 onClick={() => {
//                   setSelectedMenuItem(menu.title);
//                   if (menu.title === "Help") {
//                     openHelpModal();
//                   } else if (menu.title === "Product") {
//                     showComingSoonAlert();
//                   } else {
//                     navigate(`/${menu.address}`);
//                   }
//                 }}
//               >
//                 <Link to="#">
//                   <span className="text-2xl block float-left mr-2">
//                     {menu.icon}
//                   </span>
//                   <span
//                     className={`text-base font-medium flex-1 ${
//                       !props.open && "hidden"
//                     } `}
//                   >
//                     {menu.title}
//                   </span>
//                 </Link>
//               </li>
//             ))}
//           <li
//             className="text-gray-600 text-sm flex items-center gap-x-4 cursor-pointer p-2 ho duration-400 transition ease-in rounded-md mt-2"
//             onClick={userLogout}
//           >
//             <span className="text-2xl block float-left -mr-2">
//               <IoLogOut />
//             </span>
//             <span
//               className={`text-base font-medium flex-1 ${
//                 !props.open && "hidden"
//               } `}
//             >
//               Log out
//             </span>
//           </li>
//         </ul>
//       </div>
//       {/* Help Modal */}
//       {selectedMenuItem === "Help" && (
//         <Dialog open={isHelpModalOpen} onClose={closeHelpModal}>
//           <DialogContent>
//             <div className="flex justify-end">
//               <IoMdClose
//                 className="text-2xl cursor-pointer"
//                 onClick={closeHelpModal}
//               />
//             </div>
//             <h2 className="text-2xl ">Leave a message</h2>
//             <form action={FORM_ENDPOINT}
//               onSubmit={handleSubmit}
//               method="POST"
//             >
//               <input
//                 type="text"
//                 placeholder="Type your message..."
//                 value={formData.userHelpMessage}
//                 className="w-full py-2 my-3 border border-gray-100"
//                 name="User Help Request"
//                 onChange={(e) =>
//                   setFormData({
//                     ...formData,
//                     userHelpMessage: e.target.value,
//                   })
//                 }
//               />
//               <input
//                 type="email"
//                 placeholder="Your Email Address"
//                 value={formData.userEmail}
//                 className="w-full py-2 my-3 border border-gray-100"
//                 name="User Email Address"
//                 onChange={(e) =>
//                   setFormData({
//                     ...formData,
//                     userEmail: e.target.value,
//                   })
//                 }
//               />
//               <div className="flex justify-center">
//                 <button
//                 type ="submit"
//                   variant="contained"
//                   className="block bg-c-4 px-4 py-3 rounded-md"
//                 >
//                   Submit
//                 </button>
//                 {submitted && (alert('message sent'))}
//               </div>
//             </form>
//           </DialogContent>
//         </Dialog>
//       )}
//     </div>
//   );
// }

// export default Sidebar;



import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoIosArrowRoundBack } from "react-icons/io";
import { TbLayoutDashboard } from "react-icons/tb";
import { IoLogOut } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import { Dialog, DialogContent } from "@mui/material";

function Sidebar(props) {
  const Menus = props.data;
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [isHelpModalOpen, setHelpModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    userHelpMessage: "",
    userEmail: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const openHelpModal = () => {
    setHelpModalOpen(true);
  };

  const closeHelpModal = () => {
    setHelpModalOpen(false);
    // Reset form data and submission status when modal is closed
    setFormData({
      userHelpMessage: "",
      userEmail: "",
    });
    setSubmitted(false);
  };

  const showComingSoonAlert = () => {
    alert("This service is coming soon!");
  };

  // Logout handler function
  const navigate = useNavigate();
  function userLogout() {
    localStorage.removeItem("token");
    navigate("/");
  }

  const FORM_ENDPOINT =
    "https://public.herotofu.com/v1/e1f49330-e058-11ee-8980-3397320e035b";
  const handleSubmit = (e) => {
    e.preventDefault();

    const inputs = e.target.elements;
    const data = {};

    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].name) {
        data[inputs[i].name] = inputs[i].value;
      }
    }

    fetch(FORM_ENDPOINT, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Form response was not ok");
        }

        setSubmitted(true);
        setIsSubmitting(false);
        setFormData({
          userHelpMessage: "",
          userEmail: "",
        });
      })
      .catch((err) => {
        // Submit the form manually
        e.target.submit();
      });
  };

  return (
    <div className="flex fixed">
      <div
        className={`bg-white h-screen p-2 pt-8 ${
          props.open ? "w-48" : "w-16"
        } duration-300 relative max-sm:w-12`}
      >
        <IoIosArrowRoundBack
          className={`bg-white text-black text-2xl rounded-full absolute -right-3 top-9 border border-black cursor-pointer ${
            !props.open && "rotate-180"
          } max-sm:hidden`}
          onClick={() => props.setOpen(!props.open)}
        />
        <div className="inline-flex">
          <TbLayoutDashboard className="text-black text-4xl rounded cursor-pointer block float-left mr-2" />
          <h1
            className={`text-black origin-left font-medium text-2xl ${
              !props.open && "scale-0"
            }`}
          >
            Dashboard
          </h1>
        </div>
        <ul className="pt-2">
          {Menus &&
            Menus.map((menu, index) => (
              <li
                key={index}
                className="text-gray-600 text-sm flex items-center gap-x-4 cursor-pointer p-2 ho duration-400 transition ease-in rounded-md mt-2"
                onClick={() => {
                  setSelectedMenuItem(menu.title);
                  if (menu.title === "Help") {
                    openHelpModal();
                  } else if (menu.title === "Product") {
                    showComingSoonAlert();
                  } else {
                    navigate(`/${menu.address}`);
                  }
                }}
              >
                <Link to="#">
                  <span className="text-2xl block float-left mr-2">
                    {menu.icon}
                  </span>
                  <span
                    className={`text-base font-medium flex-1 ${
                      !props.open && "hidden"
                    } `}
                  >
                    {menu.title}
                  </span>
                </Link>
              </li>
            ))}
          <li
            className="text-gray-600 text-sm flex items-center gap-x-4 cursor-pointer p-2 ho duration-400 transition ease-in rounded-md mt-2"
            onClick={userLogout}
          >
            <span className="text-2xl block float-left -mr-2">
              <IoLogOut />
            </span>
            <span
              className={`text-base font-medium flex-1 ${
                !props.open && "hidden"
              } `}
            >
              Log out
            </span>
          </li>
        </ul>
      </div>
      {/* Help Modal */}
      {selectedMenuItem === "Help" && (
        <Dialog open={isHelpModalOpen} onClose={closeHelpModal}>
          <DialogContent>
            <div className="flex justify-end">
              <IoMdClose
                className="text-2xl cursor-pointer"
                onClick={closeHelpModal}
              />
            </div>
            <h2 className="text-2xl ">Leave a message</h2>
            <form
              action={FORM_ENDPOINT}
              onSubmit={handleSubmit}
              method="POST"
            >
              <input
                type="text"
                placeholder="Type your message..."
                value={formData.userHelpMessage}
                className="w-full py-2 my-3 border border-gray-100"
                name="User Help Request"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    userHelpMessage: e.target.value,
                  })
                }
              />
              <input
                type="email"
                placeholder="Your Email Address"
                value={formData.userEmail}
                className="w-full py-2 my-3 border border-gray-100"
                name="User Email Address"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    userEmail: e.target.value,
                  })
                }
              />
              <div className="flex justify-center">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="block bg-c-4 px-4 py-3 rounded-md"
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </button>
              </div>
            </form>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

export default Sidebar;
