import React from "react";
import img from "../assets/images/peoplehub-cocreation-model-800x800.png";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

import philosophy from "../assets/images/INVESTMENT-PHILOSOPHY-1280x1268.jpg";
import history from "../assets/images/history.jpeg";
import culture from "../assets/images/culture.jpeg";

import { TiTick } from "react-icons/ti";
import Footer from "../components/Footer";

import Navbar from "../components/Navbar";
import { Helmet } from "react-helmet";

function About() {
  return (
    <>
      <Helmet>
        <title>About Us | Ascentia Partner Wealth</title>
        <meta
          name="description"
          content="Ascentia Partners Wealth Management is a diverse conglomerate
              founded on solid values, backed by years of investment experience
              by a series of partnered investors from the USA, Canada and all
              around the world coming together to achieve a simple goal To
              Invest & Succeed."
        />
        <meta
          content="investment platform, Wealth Management, investors, about us, financial empowerment in Canada, powerful mantra, coalition of
                  investors from the USA, Canada, and beyond, secure and reliable
                    platform for your financial success"
          name="keywords"
        />
      </Helmet>
      <Navbar />
      <section>
        <div className="w-full bg-no-repeat bg-cover bg-bottom bg-luminary">
          <div className="w-full py-52 max-md:py-56 h-ful">
            <div className="mx-auto w-7/12 max-md:w-11/12 max-xl:w-9/12 max-2xl:w-10/12">
              <h1 className="text-6xl text-white font-semibold">About Us </h1>
              <p className="text-md text-white font-semibold w-2/3 max-md:w-full max-lg:w-8/12 pt-3">
                Ascentia Partners Wealth Management is a diverse conglomerate
                founded on solid values, backed by years of investment
                experience by a series of partnered investors from the USA,
                Canada and all around the world coming together to achieve a
                simple goal To Invest & Succeed.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="mx-auto w-7/12 max-md:w-11/12 max-xl:w-9/12 my-28 max-sm:my-20 max-2xl:w-10/12">
          <h1 className="text-3xl text-black font-semibold  mb-3">
            Putting our client first
          </h1>
          <p className="text-md w-2/3 max-sm:text-lg max-sm:w-full text-gray-400">
            we empower clients by helping them take control of their financial
            lives.
          </p>
          <div className="grid md:grid-cols-3 mx-auto gap-10 py-10 mb-10">
            <div className="">
              <Card>
                <CardMedia
                  sx={{ height: 240 }}
                  image={philosophy}
                  title="client"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Philosophy
                  </Typography>
                  <p className="text-xs">
                    Our philosophy centers on the powerful mantra, "To Invest &
                    Succeed." With years of collective investment experience, we
                    strategically diversify across sectors such as Real Estate,
                    Hospitality, and Education. Through partnerships or full
                    ownership, our aim is to continually grow and strengthen our
                    position as a dynamic business house.
                  </p>
                </CardContent>
              </Card>
            </div>
            <div className="">
              <Card>
                <CardMedia
                  sx={{ height: 240 }}
                  image={culture}
                  title="client"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Culture
                  </Typography>
                  <p className="text-xs">
                    At Ascentia Partners, our culture thrives on shared values
                    and collaboration. We are a diverse conglomerate, united by
                    partners worldwide, committed to the simple goal of
                    investing and succeeding together.
                  </p>
                </CardContent>
              </Card>
            </div>
            <div className="">
              <Card>
                <CardMedia
                  sx={{ height: 240 }}
                  image={history}
                  title="client"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    History
                  </Typography>
                  <p className="text-xs">
                    Ascentia Partners Wealth Management traces its roots to
                    successful business platforms. Formed by a coalition of
                    investors from the USA, Canada, and beyond, our history is
                    marked by exploration and acquisition of ventures,
                    highlighting our commitment to dynamic growth and
                    prosperity.
                  </p>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
        <div className="mx-auto w-7/12 max-md:w-11/12 max-xl:w-9/12 mb-40 max-lg:mb-20 max-2xl:w-10/12">
          <div className="grid grid-cols-2 max-lg:grid-cols-1 lg:relative">
            <div className="max-lg:order-last">
              <p className="font-serif text-sm uppercase tracking-widest">
                universal investment lifecycle
              </p>
              <h1 className="text-4xl max-sm:text-3xl font-normal max-sm:mb-5 mb-8 mt-3">
                We provide the only comprehensive solution for you{" "}
              </h1>
              <p>
                Ascentia Partners Wealth Management embraces a Universal
                Investment Lifecycle, uniting global partners to explore
                opportunities across various sectors. With a philosophy centered
                on "To Invest & Succeed," our strategic diversification spans
                Real Estate, Hospitality, Education, and more. Our history is
                marked by successful ventures, highlighting adaptability through
                partnerships or full ownership. Continuously exploring and
                acquiring, we position ourselves as a dynamic and
                well-diversified business house. This lifecycle reflects our
                commitment to sustained growth and prosperity, navigating the
                investment landscape with a collaborative and global
                perspective.
              </p>
            </div>
            <img
              src={img}
              alt="info"
              className="lg:absolute lg:-top-10 lg:right-0 lg:w-5/12 max-sm:w-8/12 w-6/12 mx-auto max-lg:mb-16"
            />
          </div>
        </div>
      </section>

      <section className="w-full pt-36 bg-c-4 grid place-items-center ">
        <div className="mx-auto w-6/12 max-md:w-10/12 max-xl:w-8/12 max-2xl:w-9/12">
          <div className="grid lg:grid-cols-5 grid-cols-1 gap-x-4 mb-20">
            <div className="lg:col-span-2 bg-why bg-cover bg-no-repeat bg-center max-lg:order- max-lg:mt-10 py-36 max-lg:py-44"></div>
            <div className="lg:col-span-3 max-lg:mt-10 ">
              <div className="">
                <span className="pr-1 mr-2 bg-c-4 pt-4"></span>
                <span className="uppercase text-c-1 font-semibold text-4xl">
                  Trust In Us
                </span>
              </div>
              <p className="text-2xl font-semibol text-black max-sm:text-xl ">
                An investment that takes you to great heights
              </p>
              <p className="text-sm py-3 pb-5">
                Experience the confidence that comes with choosing Ascentia
                Partners Wealth Management for your diverse investment needs
              </p>
              <div className="w-full flex mb-5">
                <div className="bg-c-1 h-12 max-sm:h-8 max-sm:pt-1 max-sm:px-1 pt-2 px-3 rounded-md mr-5">
                  <TiTick className="text-3xl max-sm:text-xl text-c-4" />
                </div>
                <div>
                  <p className="text-xl font-semibold">
                    Invest with Confidence
                  </p>
                  <p className="text-sm">
                    Backed by a robust foundation of values and years of
                    investment expertise. we offer a secure and reliable
                    platform for your financial success
                  </p>
                </div>
              </div>
              <div className="w-full flex mb-5">
                <div className="bg-c-1 h-12 max-sm:h-8 max-sm:pt-1 max-sm:px-1 pt-2 px-3 rounded-md mr-5">
                  <TiTick className="text-3xl max-sm:text-xl text-c-4" />
                </div>
                <div>
                  <p className="text-xl font-semibold">Secure Platfrom</p>
                  <p className="text-xsm">
                    Experience the confidence that comes from investing in a
                    platform designed with your security in mind. Ascentia
                    Partners — Your Trusted Path to a Secure Financial Future.
                  </p>
                </div>
              </div>
              <div className="w-full flex mb-5">
                <div className="bg-c-1 h-12 max-sm:h-8 max-sm:pt-1 max-sm:px-1 pt-2 px-3 rounded-md mr-5">
                  <TiTick className="text-3xl max-sm:text-xl text-c-4" />
                </div>
                <div>
                  <p className="text-xl font-semibold">Trusted by Investor</p>
                  <p className="text-sm">
                    Our track record of success and commitment to integrity make
                    us a reliable choice for investors worldwide
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default About;
