import { React, useState } from "react";
import Sidebar from "../components/Sidebar";
import { BsPeople } from "react-icons/bs";
import { FiArrowDownLeft } from "react-icons/fi";
import { TbPackages } from "react-icons/tb";
import { GrMoney } from "react-icons/gr";
import { TbLayoutDashboard } from "react-icons/tb";
import { useQuery } from "react-query";
import { getAllUser } from "../helpers/helper";
import toast, { Toaster } from "react-hot-toast";
import InvestmentStatusTable from "../components/InvestmentStatusTable";
// import { useNavigate } from "react-router";

function AdminDashboardPage() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { isError, data: Users } = useQuery({
    queryKey: ["UsersData"],
    queryFn: getAllUser, // Pass the function reference without calling it
  });

  const tableHead = "Recent Orders";

  const columnData = [
    {
      id: 1,
      ID: "Order ID",
      name: "Customer",
      status: "Status",
      amount: "Amount",
      total: "Total",
    },
  ];

  const Menus = [
    {
      title: "Dashboard",
      icon: <TbLayoutDashboard />,
      address: "dashboard/admin",
    },
    { title: "Product", icon: <TbPackages />, address: "dashboard/admin/adminProduct" },
  ];

  function sumUserDeposits(usersArray) {
    return usersArray.reduce(
      (totalDeposit, user) => totalDeposit + user.deposit,
      0
    );
  }

  function sumUserTotalAmount(usersArray) {
    return usersArray.reduce(
      (totalDeposit, user) => totalDeposit + user.total,
      0
    );
  }

  return (
    <div className="flex bg-teal-400">
      <Toaster position="top-center"></Toaster>
      <Sidebar open={sidebarOpen} setOpen={setSidebarOpen} data={Menus} />
      <div className={`${sidebarOpen ? "w-48" : "w-16"} bg-slate-200`}></div>
      <div className="bg-slate-200 w-full min-h-screen">
        <div className="bg-slate-200 w-10/12 max-sm:w-10/12 max-sm:ml-14 mx-auto">
          <p className="text-4xl text-black mt-8 max-sm:ml-3">Admin</p>
          <div className="md:bg-white w-full h-36 rounded-lg max-sm:w-11/12 max-sm:ml-2 max-sm:mt-5 max-md:mb-40 mt-10 flex flex-wrap items-center justify-evenly">
            <div className="flex w-3/12 max-md:w-full bg-white max-md:mb-5 md:justify-center max-md:px-6 max-md:py-3  justify-between rounded-md">
              <div className="bg-c-1 px-4 py-4 rounded-full mr-3 max-sm:mt-2">
                <BsPeople className="sm:text-4xl  text-c-4 " />
              </div>
              <div className="mt-0 leading-3">
                <p className="text-xs text-gray-400">Total customers</p>
                <p className="text-xl font-bold">
                  {Users ? Users.length + 1 : "Loading..."}
                </p>
              </div>
            </div>
            <div className="flex w-3/12 max-md:w-full bg-white max-md:mb-5 md:justify-center max-md:px-6 max-md:py-3 border-x-2 justify-between rounded-md">
              <div className="bg-c-1 px-4 py-4 rounded-full mr-3 max-sm:mt-2">
                <FiArrowDownLeft className="sm:text-4xl text-c-4 " />
              </div>
              <div className="mt-0 leading-3">
                <p className="text-xs text-gray-400">Total Deposit</p>
                <p className="text-xl font-bold">
                  {Users ? sumUserDeposits(Users) : "Loading..."}
                </p>
              </div>
            </div>
            <div className="flex w-3/12 max-md:w-full bg-white max-md:mb-40 md:justify-center max-md:px-6 max-md:py-3  justify-between rounded-md">
              <div className="bg-c-1 px-4 py-4 rounded-full mr-3 max-sm:mt-2">
                <GrMoney className="sm:text-4xl text-c-4 " />
              </div>
              <div className="mt-0 leading-3">
                <p className="text-xs text-gray-400">Total Amount</p>
                <p className="text-xl font-bold">
                  {Users ? sumUserTotalAmount(Users) : "Loading..."}
                </p>
              </div>
            </div>
          </div>
          <div className="bg-white w-full rounded-lg max-sm:w-11/12 max-sm:ml-2 max-sm:my-5 my-10 py-10 overflow-x-scroll">
            {Users && (
              <InvestmentStatusTable
                rowData={Users}
                columnData={columnData}
                tableHeader={tableHead}
              />
            )}
            {isError && toast.error("An error occurred")}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminDashboardPage;
