import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import SearchInput from "./SearchInput";

const TableComponent = ({
  rowData,
  columnData,
  tableHeader,
  rowscount,
  diff,
}) => {
  const [searchResults, setSearchResults] = useState(rowData);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowscount);

  const handleSearch = (query) => {
    const filteredData = rowData.filter((row) => {
      return row.name.toLowerCase().includes(query.toLowerCase());
    });
    setSearchResults(filteredData);
    setPage(0); // Reset to the first page when search changes
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page when rows per page changes
  };

  const visibleRows = searchResults.slice(
    page * rowsPerPage,
    (page + 1) * rowsPerPage
  );

  console.log(visibleRows);
  // const [smallText, setSmallText] = useState(diff)

  return (
    <div>
      <div className="w-full flex justify-between sm:px-10 max-sm:pl-7 max-sm:pr-3">
        <p className="text-xl font-bold">{tableHeader}</p>
        <div>
          <SearchInput onSearch={handleSearch} />
        </div>
      </div>
      <div className="px-10 max-sm:px-5">
        <Table>
          <TableHead>
            {columnData.map((row, index) => (
              <TableRow key={index}>
                <TableCell>
                  <p className="text-sm font-semibold w-20">{row.date}</p>
                </TableCell>
                <TableCell>
                  <p className="text-sm font-semibold w-20">{row.type}</p>
                </TableCell>
                {/* <TableCell>
                  <p className="text-sm font-semibold">{row.status}</p>
                </TableCell> */}
                <TableCell>
                  <p className="text-sm font-semibold">{row.amount}</p>
                </TableCell>
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {visibleRows.map((row, index) => (
              <TableRow key={index}>
                <TableCell>
                  <p className="text-xxs">{row.date}</p>
                </TableCell>
                <TableCell>
                  <p className="text-xxs">{row.type}</p>
                </TableCell>
                {/* <TableCell>
                  <p className="text-xxs">{row.status}</p>
                </TableCell> */}
                <TableCell>
                  <p className="text-xxs">{row.amount}</p>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className="w-full">
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={searchResults.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </div>
  );
};

export default TableComponent;
