import { React } from "react";
import FormInput from "../components/FormInput";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { loginUser, passwordValidate } from "../helpers/helper";
import toast, { Toaster } from "react-hot-toast";
import { Helmet } from "react-helmet";

function LoginPage() {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      try {
        const errors = await passwordValidate(values); // Validate the password
        if (Object.keys(errors).length > 0) {
          // If there are validation errors, display them and prevent login
          Object.values(errors).forEach((error) => toast.error(error));
          return;
        }

        let loginPromise = loginUser({
          email: values.email,
          password: values.password,
        });

        toast.promise(loginPromise, {
          loading: "Checking...",
          success: <b>Login Successfully...!</b>,
          error: <b>Wrong Email or Password </b>,
        });

        loginPromise.then((res) => {
          let { role, token, email } = res.data;
          localStorage.setItem(
            "token",
            JSON.stringify({ token: token, role: "admin" })
          );

          if (role === "admin") {
            return navigate("/dashboard/admin");
          } else {
            return navigate(`/dashboard/user/${email}`);
          }
        });
      } catch (error) {
        toast.error(error.message || "An error occurred");
      }
    },
  });

  return (
    <>
      <Helmet>
        <title>Login | Ascentia Partner Wealth</title>
        <meta
          content="investment platform, Ascentia Partner Wealth Login, Login, Ascentia Partner Wealth investment platform "
          name="keywords"
        />
      </Helmet>
      <div className="bg-c-4 max-sm:py-10 sm:h-screen w-screen min-h-screen flex items-center justify-center">
        <Toaster position="top-center"></Toaster>

        <div className=" bg-white sm:px-20 sm:py-10 px-10 py-5 rounded-lg">
          <div className="flex">
            <p className="text-c-4 text-3xl font-semibold text-center">Login</p>
            <span className="text-c-4 px-4 text-3xl font-semibold">|</span>
            <Link to="/register">
              <p className="text-c-1 text-3xl font-semibold text-center">
                Register
              </p>
            </Link>
          </div>

          <form onSubmit={formik.handleSubmit}>
            <FormInput
              type="email"
              name="email"
              {...formik.getFieldProps("email")}
            />
            <FormInput
              type="password"
              name="password"
              {...formik.getFieldProps("password")}
            />
            <button className="bg-c-1 text-c-4 w-full py-2" type="submit">
              Submit
            </button>
            <div className="flex py-3 justify-between">
              <Link to="/forget-password">
                <p className="text-xs text-blue-600 underline">
                  Forgot Password?
                </p>
              </Link>
              <Link to="/register">
                <p className="text-xs text-blue-600 underline">Register</p>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
