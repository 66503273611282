import { useFormik } from "formik";
import { React } from "react";
import toast, { Toaster } from "react-hot-toast";
import FormInput from "../components/FormInput";
import { useNavigate, useParams } from "react-router-dom";
import { resetPassword } from "../helpers/helper";
import { Helmet } from "react-helmet";

function Reset() {
  const { email } = useParams();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      if (values.password === values.confirmPassword) {
        let resetPromise = resetPassword({ email, password: values.password });

        toast.promise(resetPromise, {
          loading: "Updating...",
          success: <b>Reset Successfully...!</b>,
          error: <b>Could not Reset!</b>,
        });

        resetPromise.then(function () {
          navigate("/login");
        });
      } else {
        return toast.error("Passwords Don't Match...!");
      }
    },
  });

  return (
    <>
      <Helmet>
        <title>Forget Password | Ascentia Partner Wealth</title>
      </Helmet>
      <div className="bg-c-4 max-sm:py-10 sm:h-screen w-screen min-h-screen flex items-center justify-center">
        <Toaster position="top-center"></Toaster>

        <div className=" bg-white sm:px-20 sm:py-10 px-10 py-5 rounded-lg">
          <p className="text-c-1 text-3xl font-semibold text-center">
            Enter New Password
          </p>

          <form onSubmit={formik.handleSubmit}>
            <FormInput
              type="password"
              name="password"
              {...formik.getFieldProps("password")}
            />
            <FormInput
              type="password"
              name="confirmPassword"
              {...formik.getFieldProps("confirmPassword")}
            />
            <button className="bg-c-1 w-full py-2" type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default Reset;
