import React from 'react'
import Footer from '../components/Footer';

import Navbar from "../components/Navbar";


import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import client1 from "../assets/images/clients/0007-1-scaled.jpg";
import client2 from "../assets/images/clients/ApexOne_Image-1.png";
import client3 from "../assets/images/clients/developments-clarkson_go-listing.jpg";
import client4 from "../assets/images/clients/Nautica_Pool_2.jpg";
import client5 from "../assets/images/clients/Rendering-of-207-209-West-140th-Street-Courtesy-of-Exact-Capital.jpg";
import client6 from "../assets/images/clients/sorgenfri-malmo-sweden-600x383.jpg";

function Clients() {

    const cardData = [
        {
            image: client1,
            title: 'Capital Group funds',
            description: 'Ascentia Partners Wealth Management helped Los Angeles based Capital Group funds raise $17 million in 8 months, becoming one of Ascentia Partners 15 most successful fundraisers in 2020.',
        },
        {
            image: client2,
            title: 'APEXONE',
            description: 'ApexOne turned to Ascentia Partners Wealth Management for help facilitating investor relations activities, allowing the firm to raise $155 million in 11 months.',
        },
        {
            image: client3,
            title: 'SLATE',
            description: 'Canadian-based firm’s search for an investor relations management software solution led them to Ascentia Partners Wealth Management.',
        },
        {
            image: client4,
            title: 'Capital Group funds',
            description: 'Canadian-based firm’s search for an investor relations management software solution led them to Ascentia Partners Wealth Management.',
        },
        {
            image: client5,
            title: 'Exact Capital Group LLC',
            description: 'A New York-based real estate investment firm manages open-end fund with Ascentia Partners Wealth Management.',
        },
        {
            image: client6,
            title: 'AKION GROUP',
            description: 'Germany based investment firm implements an easier, more transparent system and elevates their investor experience.',
        },
    ]


  return (
    <div>
        <Navbar />
        <div className="w-full bg-no-repeat bg-cover bg-bottom bg-luminary">
            <div className='w-full py-20 max-md:py-56 h-ful'>
                <div className='mx-auto w-7/12 max-md:w-11/12 max-xl:w-9/12 max-2xl:w-10/12'>
                    <h1 className='text-6xl max-sm:text-3xl text-white font-semibold'>Our Achievements </h1>
                    <p className='text-md text-white sm:font-semibold w-2/3 max-md:w-full max-lg:w-8/12 pt-3'>
                        Ascentia Partners Wealth Management proudly boasts a history of successful collaborations 
                        and achievements with previous clients, demonstrating our commitment to excellence through 
                        strategic investments, project management, and diverse ventures in sectors such as real estate, 
                        hospitality, education, and representation of international companies.
                    </p>
                </div>
            </div>
        </div>

        <section className="w-full py-20 bg-c-">
        <div className="mx-auto w-6/12 max-md:w-10/12 max-xl:w-8/12 max-2xl:w-9/12 flex justify-center items-center flex-wrap">
          <div className="">
            <div className="grid md:grid-cols-4 mx-auto gap-10">
                {cardData.map((card, index) => (      
                    <div className="col-span-2" key={index}>
                        <Card>
                            <CardMedia
                                sx={{ height: 240 }}
                                image={card.image}
                                title={card.title}
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    {card.title}
                                </Typography>
                                <p className="text-xs">
                                    {card.description}
                                </p>
                            </CardContent>
                        
                        </Card>
                    </div>
                ))}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  )
}

export default Clients