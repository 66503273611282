import axios from "axios";
import toast from "react-hot-toast";

axios.defaults.baseURL = process.env.REACT_APP_SERVER_DOMAIN;

/** Make APi request */

/** authenticate for login page */
export async function authenticate(email) {
  try {
    return await axios.post("/api/authenticate", { email });
  } catch (error) {
    return { error: "Email doesn't exist...." };
  }
}

/** validate Register Page email */
export async function emailValidate(values) {
  const errors = emailVerify({}, values);

  if (values.email) {
    // check user exist or not
    const { status } = await authenticate(values.email);

    if (status === 200) {
      errors.exist = toast.error("email already exist");
      return;
    }
  }
  return errors;
}

/** validate email */
function emailVerify(error = {}, values) {
  if (!values.email) {
    error.email = toast.error("Email Required...!");
  } else if (values.email.includes(" ")) {
    error.email = toast.error("Invalid Email...!");
  }

  return error;
}

/** get User details */
export async function getUser(email) {
  try {
    const { data } = await axios.get(`/api/user/${email}`);
    return { data };
  } catch (error) {
    return { error: "Password doesn't match...." };
  }
}
/** get User details */
export async function getAllUser() {
  try {
    const response = await axios.get("/api");
    return response.data;
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error; // Rethrow the error to be caught in the component
  }
}

/** register user function */
export async function registerUser({ email, password, phoneNumber, jobTitle }) {
  try {
    const { data } = await axios.post("/api/register", {
      email,
      password,
      phoneNumber,
      jobTitle,
    });
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject({ error: "An error occured" });
  }
}

/** validate password */
function passwordVerify(errors = {}, values) {
  /* eslint-disable no-useless-escape */
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

  if (!values.password) {
    errors.password = toast.error("Password Required...!");
  } else if (values.password.includes(" ")) {
    errors.password = toast.error("Wrong Password...!");
  } else if (values.password.length < 4) {
    errors.password = toast.error(
      "Password must be more than 4 characters long"
    );
  } else if (!specialChars.test(values.password)) {
    errors.password = toast.error("Password must have special character");
  }

  return errors;
}

/** login a user*/
export async function loginUser({ email, password }) {
  try {
    const { data } = await axios.post("/api/login", { email, password });
    return Promise.resolve({ data });
  } catch (error) {
    throw new Error(error.response.data.error);
  }
}

/** validate login password */
export async function passwordValidate(values) {
  const errors = passwordVerify({}, values);
  return errors;
}

/** generate OTP */
export async function generateOTP(email) {
  try {
    const {
      data: { code },
      status,
    } = await axios.get("/api/generateOTP", { params: { email } });

    // send mail with the OTP
    if (status === 201) {
      let text = `Your Password Recovery OTP is ${code}. Verify and recover your password.`;
      await axios.post("/api/registerMail", {
        userEmail: email,
        text,
        subject: "Password Recovery OTP",
      });
    }
    return Promise.resolve(code);
  } catch (error) {
    throw new Error(error.response.data.error);
  }
}

/** verify OTP */
export async function verifyOTP({ email, code }) {
  try {
    const { data, status } = await axios.get("/api/verifyOTP", {
      params: { email, code },
    });
    return { data, status };
  } catch (error) {
    throw new Error(error.response.data.error);
  }
}

/** reset password */
export async function resetPassword({ email, password }) {
  try {
    const { data, status } = await axios.put("/api/resetPassword", {
      email,
      password,
    });
    return Promise.resolve({ data, status });
  } catch (error) {
    throw new Error(error.response.data.error);
  }
}

/** update user profile function */
export async function updateUser(_id, body) {
  try {
    const data = await axios.put("/api/updateuser", { _id, ...body });

    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject({ error: "Couldn't Update Profile...!" });
  }
}
/** update user transaction */
export async function payment(_id, body) {
  try {
    const data = await axios.post("/api/transaction", { _id, ...body });

    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject({ error: "Couldn't Update Payment...!" });
  }
}
