
import { Link, NavLink } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import logo from '../assets/images/logo.png';

const Navbar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    // Add event listener for scroll when sidebar is open
    if (sidebarOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    // Remove event listener when component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [sidebarOpen]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <header className='mx-auto w-6/12 max-md:w-10/12 max-xl:w-8/12 max-2xl:w-9/12'>
      <nav className="flex items-center justify-between p-4 bg-white">
        {/* Dummy Logo */}
        <div className="text-black">
          <img src={logo} alt='logo' className="w-40 max-sm:w-32" />
        </div>

        {/* Hamburger Button for Small Screens */}
        <button
          className="lg:hidden text-black focus:outline-none"
          onClick={toggleSidebar}
        >
          &#9776; {/* Hamburger Icon */}
        </button>

        <div className={`lg:flex space-x-4 max-lg:hidden`}>
          <NavLink to="/" activeClassName="text-c-1" className="text-c-1 transition-all duration-500 ease-in-out text-ho mt-1">Home</NavLink>
          <NavLink to="/about" activeClassName="text-c-1" className="text-c-1 transition-all duration-500 ease-in-out text-ho mt-1">About Us</NavLink>
          <NavLink to="/clients" activeClassName="text-c-1" className="text-c-1 transition-all duration-500 ease-in-out text-ho mt-1">Our Clients</NavLink>
          <NavLink to="/contact" activeClassName="text-c-1" className="text-c-1 transition-all duration-500 ease-in-out text-ho mt-1">Contact Us</NavLink>
          <Link to="/login">
            <button className="px-10 rounded-md py-2 bg-c-4 text-black uppercase font-medium tracking-wider">
              Login
            </button>
          </Link> 
        </div>

        {/* Sidebar for Small Screens */}
         {sidebarOpen && (
          <div className="lg:hidden fixed inset-0 bg-opacity-75 h-screen z-50">
            <div className="fixed right-0 top-0 h-full w-1/2  bg-white p-4">
              <div className='w-full grid '>
                <button
                  className="text-black right-0 focus:outline-none text-right"
                  onClick={toggleSidebar}
                >
                  &#10005; {/* Close Icon */}
                </button>
              </div>

              <div className="flex flex-col items-center h-2/4 ">
                <Link to="/" className="text-c-1 text-ho transition-all duration-500 ease-in-out h-1/4 mt">Home</Link>
                <Link to="/about" className="text-c-1 text-ho transition-all duration-500 ease-in-out h-1/4">About Us</Link>
                <Link to="/clients" className="text-c-1 text-ho transition-all duration-500 ease-in-out h-1/4">Our Clients</Link>
                <Link to="/contact" className="text-c-1 text-ho transition-all duration-500 ease-in-out h-1/4">Contact Us</Link>
                <Link to="/login">
                  <button className="px-10 rounded-md py-2 bg-c-4 max text-black uppercase font-medium tracking-wider">
                    Login
                  </button>
                </Link> 
              </div>
            </div>
          </div>
        )}
      </nav>
    </header>
  );
};

export default Navbar;
