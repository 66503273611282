import React from "react";
import { BsGlobe2 } from "react-icons/bs";
import { GiCash } from "react-icons/gi";
import { PiToolbox } from "react-icons/pi";
import { MdOutlineSupportAgent } from "react-icons/md";
import { GrLineChart } from "react-icons/gr";
import { FaHospitalUser } from "react-icons/fa6";
import { IoBusinessOutline } from "react-icons/io5";
import { MdOutlineRealEstateAgent } from "react-icons/md";
import { FaCoins } from "react-icons/fa";
import { HiArrowLongRight } from "react-icons/hi2";
import { TiTick } from "react-icons/ti";
import { GoTasklist } from "react-icons/go";

import laptop from "../assets/images/image.jpg";
import client1 from "../assets/images/clients/0007-1-scaled.jpg";
import client2 from "../assets/images/clients/ApexOne_Image-1.png";
import client3 from "../assets/images/clients/developments-clarkson_go-listing.jpg";
import client4 from "../assets/images/clients/Nautica_Pool_2.jpg";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Home Page | Ascentia Partner Wealth</title>
      </Helmet>
      <Navbar />
      <section className="first-view jumbotron-bg bg-no-repeat bg-cover bg-bottom jumbotron-b h-scree relative">
        <div className="w-full py-96 max-md:py-80 max-sm:py-64 h-full grid place-items-center relative">
          <div className="bg-green-600 top-0 left-0 w-full h-full opacity-10 absolute jumbotron-b"></div>
          <div className="bg-black top-0 left-0 w-full h-full opacity-40 absolute jumbotron-b"></div>
          <div className="absolute w-full">
            <div className="mx-auto w-6/12 max-md:w-10/12 max-xl:w-8/12 max-2xl:w-9/12">
              <p className="text-6xl max-xl:text-5xl max-sm:text-4xl font-bold w-7/12 max-md:w-11/12 max-xl:w-10/12 max-2xl:w-9/12 text-white">
                Make your worldwide investment easy
              </p>
              <p className="w-7/12 max-lg:w-11/12 max-lg:text-sm py-3 font-semibold text-white">
                Choose Ascentia Partners for unparalleled investment solutions.
                With a foundation in solid values, we bring expertise and
                creativity to every venture, ensuring customer satisfaction.
              </p>
              <Link to="/register">
                <button className="px-10 rounded-md py-2 bg-c-4 text-black uppercase font-medium tracking-wider">
                  Get Started
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="mx-auto w-6/12 max-md:w-10/12 max-xl:w-8/12 max-2xl:w-9/12 py-16 max-md:py-10">
        <div className="grid grid-cols-4 max-lg:grid-cols-2 max-sm:grid-cols-1 gap-4">
          <div className="px-5 py-12 rounded-md bg-gradient-to-br from-blue-700 via-blue-900 bg-black transition-500 hover:scale-105 duration-500 ease-in-out transform transition-transform">
            <BsGlobe2 className="text-c-4 text-4xl" />
            <p className="text-2xl font-medium py-3 text-white">
              Wide Service Range
            </p>
            <p className="text-white text-sm">
              Our diverse offerings cater to your varied needs, ensuring
              comprehensive solutions and unmatched satisfaction
            </p>
          </div>
          <div className="px-5 py-12 rounded-lg bg-white shadow-xl border border-slate-100 transition-500 hover:scale-105 duration-500 ease-in-out transform transition-transform">
            <GiCash className="text-c-4 text-4xl" />
            <p className="text-2xl font-medium py-3 text-black">
              Transparent Pricing
            </p>
            <p className="text-black text-sm">
              At Ascentia, we believe in clear and honest pricing structures,
              ensuring you have a complete understanding of our services without
              hidden costs
            </p>
          </div>
          <div className="px-5 py-12 rounded-lg bg-white shadow-xl border border-slate-100 transition-500 hover:scale-105 duration-500 ease-in-out transform transition-transform">
            <PiToolbox className="text-c-4 text-4xl" />
            <p className="text-2xl font-medium py-3 text-black">
              Innovative Tools
            </p>
            <p className="text-black text-sm">
              Unlock possibilities with our innovative tools. we invest in
              cutting-edge technologies and solutions to enhance your
              experience, Choose progress with our forward-thinking approach.
            </p>
          </div>
          <div className="px-5 py-12 rounded-lg bg-white shadow-xl border border-slate-100 transition-500 hover:scale-105 duration-500 ease-in-out transform transition-transform">
            <MdOutlineSupportAgent className="text-c-4 text-4xl" />
            <p className="text-2xl font-medium py-3 text-black">
              Dedicated Support
            </p>
            <p className="text-black text-sm">
              Our committed team is here to assist you every step of the way.
              Whether you have questions, need assistance, or seek guidance, our
              support is tailored to meet your needs
            </p>
          </div>
        </div>
      </section>

      <section className="mx-auto w-6/12 max-md:w-10/12 max-xl:w-8/12 max-2xl:w-9/12 py-20 max-md:py-16 bg-slate-60">
        <div className="grid grid-cols-2 max-lg:grid-cols-1 gap-x-40">
          <div className="">
            <span className="pr-1 mr-2 bg-c-4 pt-4"></span>
            <span className="uppercase text-c-1 font-semibold  text-4xl">
              who are we ?
            </span>
            <p className="text-md mt-3 max-md:text-sm">
              Ascentia Partners Wealth Management is a dynamic conglomerate
              driven by solid values and extensive investment experience.
              Founded by a coalition of investors worldwide, our goal is simple:
              To Invest & Succeed. From Strategic Investments to Real Estate and
              beyond, our diversified business portfolio reflects our commitment
              to excellence. Join us at Ascentia Partners, where success is a
              journey fueled by exploration and collaboration.
            </p>
          </div>
          <div className="max-lg:mt-10">
            <img src={laptop} alt="laptop" width={650}></img>
          </div>
        </div>
      </section>

      <section className="w-full lg:h-screen max-sm:pb-10 bg-c-4 grid place-items-center ">
        <div className="mx-auto w-6/12 max-md:w-10/12 max-xl:w-8/12 max-2xl:w-9/12">
          <div className="grid lg:grid-cols-5 grid-cols-1 gap-x-4 mb-2">
            <div className="lg:col-span-2 bg-why bg-cover bg-no-repeat bg-center max-lg:order-2 max-lg:mt-10 py-36 max-lg:py-44"></div>
            <div className="lg:col-span-3 max-lg:mt-10 ">
              <div className="">
                <span className="pr-1 mr-2 bg-c-4 pt-4"></span>
                <span className="uppercase text-c-1 font-semibold text-4xl">
                  Trust In Us
                </span>
              </div>
              <p className="text-2xl font-semibol text-black max-sm:text-xl ">
                An investment that takes you to great heights
              </p>
              <p className="text-sm py-3 pb-5">
                Experience the confidence that comes with choosing Ascentia
                Partners Wealth Management for your diverse investment needs
              </p>
              <div className="w-full flex mb-5">
                <div className="bg-c-1 h-12 max-sm:h-8 max-sm:pt-1 max-sm:px-1 pt-2 px-3 rounded-md mr-5">
                  <TiTick className="text-3xl max-sm:text-xl text-c-4" />
                </div>
                <div>
                  <p className="text-xl font-semibold">
                    Invest with Confidence
                  </p>
                  <p className="text-sm">
                    Backed by a robust foundation of values and years of
                    investment expertise. we offer a secure and reliable
                    platform for your financial success
                  </p>
                </div>
              </div>
              <div className="w-full flex mb-5">
                <div className="bg-c-1 h-12 max-sm:h-8 max-sm:pt-1 max-sm:px-1 pt-2 px-3 rounded-md mr-5">
                  <TiTick className="text-3xl max-sm:text-xl text-c-4" />
                </div>
                <div>
                  <p className="text-xl font-semibold">Secure Platfrom</p>
                  <p className="text-xsm">
                    Experience the confidence that comes from investing in a
                    platform designed with your security in mind. Ascentia
                    Partners — Your Trusted Path to a Secure Financial Future.
                  </p>
                </div>
              </div>
              <div className="w-full flex mb-5">
                <div className="bg-c-1 h-12 max-sm:h-8 max-sm:pt-1 max-sm:px-1 pt-2 px-3 rounded-md mr-5">
                  <TiTick className="text-3xl max-sm:text-xl text-c-4" />
                </div>
                <div>
                  <p className="text-xl font-semibold">Trusted by Investor</p>
                  <p className="text-sm">
                    Our track record of success and commitment to integrity make
                    us a reliable choice for investors worldwide
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="w-full bg-c-2">
        <div className="mx-auto w-6/12 max-md:w-11/12 max-xl:w-8/12 max-2xl:w-9/12 rounded-lg md:py-20 py-16">
          <p className="uppercase text-c-4 text-sm font-bold text-center">
            our service plan
          </p>
          <p className="md:text-4xl text-xl text-center py-4 font-semibold font-serif text-white">
            The Best Service We Offer
          </p>
          <p className="text-xs text-center md:w-8/12 mx-auto text-white">
            Experience unparalleled support and expertise as we tailor our
            services to ensure your financial success.
          </p>
          <div className="grid grid-cols-3 max-lg:grid-cols-2 max-sm:grid-cols-1 gap-4 mt-8">
            <div className="px-5 py-12 rounded-lg bg-white shadow-xl border border-slate-100 text-center transition-500 hover:scale-105 duration-500 ease-in-out transform transition-transform">
              <GrLineChart className="text-c-4 text-4xl mx-auto" />
              <p className="text-2xl font-medium py-3 text-black">
                Strategic Investments
              </p>
              <p className="text-black text-sm">
                With a keen focus on opportunities that drive value and growth,
                we navigate the investment landscape strategically
              </p>
              <Link to="/contact">
                <HiArrowLongRight className="text-black text-3xl mt-3 mx-auto hover:text-blue-300 duration-500 ease-in-out transform hover:scale-150 transition-all" />
              </Link>
            </div>
            <div className="px-5 py-12 rounded-md bg-gradient-to-br text-center from-blue-700 via-blue-900 bg-black transition-500 hover:scale-105 duration-500 ease-in-out transform transition-transform">
              <IoBusinessOutline className="text-c-4 text-4xl mx-auto" />
              <p className="text-2xl font-medium py-3 text-white ">
                Representation of international companies
              </p>
              <p className="text-white text-sm">
                Trust us to navigate the complexities of international
                representation, ensuring mutually beneficial alliances.
              </p>
              <Link to="/contact">
                <HiArrowLongRight className="text-c-4 text-3xl mt-3 mx-auto hover:text-blue-300 duration-500 ease-in-out transform hover:scale-150 transition-all" />
              </Link>
            </div>
            <div className="px-5 py-12 rounded-lg bg-white shadow-xl border border-slate-100 text-center transition-500 hover:scale-105 duration-500 ease-in-out transform transition-transform">
              <MdOutlineRealEstateAgent className="text-c-4 text-4xl mx-auto" />
              <p className="text-2xl font-medium py-3 text-black">
                Real Estate
              </p>
              <p className="text-black text-sm">
                Our strategic investments and ventures in the real estate sector
                embody a commitment to quality, innovation, and sustainable
                development
              </p>
              <Link to="/contact">
                <HiArrowLongRight className="text-black text-3xl mt-3 mx-auto hover:text-blue-200 duration-500 ease-in-out transform hover:scale-150 transition-all" />
              </Link>
            </div>
            <div className="px-5 py-12 rounded-lg bg-white shadow-xl border border-slate-100 text-center transition-500 hover:scale-105 duration-500 ease-in-out transform transition-transform">
              <FaHospitalUser className="text-c-4 text-4xl mx-auto" />
              <p className="text-2xl font-medium py-3 text-black">
                Health Sector
              </p>
              <p className="text-black text-sm">
                we actively contribute to the health sector's growth. Ascentia
                Partners — Investing in a Healthy Future.
              </p>
              <Link to="/contact">
                <HiArrowLongRight className="text-black text-3xl mt-3 mx-auto hover:text-blue-300 duration-500 ease-in-out transform hover:scale-150 transition-all" />
              </Link>
            </div>
            <div className="px-5 py-12 rounded-lg bg-white shadow-xl border border-slate-100 text-center transition-500 hover:scale-105 duration-500 ease-in-out transform transition-transform">
              <FaCoins className="text-c-4 text-4xl mx-auto" />
              <p className="text-2xl font-medium py-3 text-black">
                Project Management
              </p>
              <p className="text-black text-sm">
                With a proven track record of overseeing diverse projects, we
                bring efficiency, innovation, and expertise to every endeavor
              </p>
              <Link to="/contact">
                <HiArrowLongRight className="text-black text-3xl mt-3 mx-auto hover:text-blue-300 duration-500 ease-in-out transform hover:scale-150 transition-all" />
              </Link>
            </div>
            <div className="px-5 py-12 rounded-lg bg-white shadow-xl border border-slate-100 text-center transition-500 hover:scale-105 duration-500 ease-in-out transform transition-transform">
              <GoTasklist className="text-c-4 text-4xl mx-auto" />
              <p className="text-2xl font-medium py-3 text-black">
                Facility Management
              </p>
              <p className="text-black text-sm">
                Ascentia Partners Wealth Management takes pride in optimizing
                operational efficiency and ensuring the highest standards of
                facility upkeep.
              </p>
              <Link to="/contact">
                <HiArrowLongRight className="text-black text-3xl mt-3 mx-auto hover:text-blue-300 duration-500 ease-in-out transform hover:scale-150 transition-all" />
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="w-full py-20 bg-c-1">
        <div className="mx-auto w-6/12 max-md:w-10/12 max-xl:w-8/12 max-2xl:w-9/12 flex justify-center items-center flex-wrap">
          <div className="">
            <p className="md:text-4xl text-xl text-center py-4 font-semibold font-serif text-white text-c-4 pb-10">
              Trusted by over 100+ clients in the world
            </p>
            <div className="grid md:grid-cols-4 mx-auto gap-10">
              <div className="col-span-2">
                <Card>
                  <CardMedia
                    sx={{ height: 240 }}
                    image={client1}
                    title="green iguana"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Capital Group funds
                    </Typography>
                    <p className="text-xs">
                      Ascentia Partners Wealth Management helped Los Angeles
                      based Capital Group funds raise $17 million in 8 months,
                      becoming one of Ascentia Partners 15 most successful
                      fundraisers in 2020.
                    </p>
                  </CardContent>
                </Card>
              </div>
              <div className="col-span-2">
                <Card>
                  <CardMedia
                    sx={{ height: 240 }}
                    image={client4}
                    title="client"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      UAE Investors Group
                    </Typography>
                    <p className="text-xs">
                      Fast leading Global Investment group based in the United
                      Arab Emirates upgrades from legacy system, raises more
                      equity in last year than previous six years combined.
                    </p>
                  </CardContent>
                </Card>
              </div>
              <div className="col-span-2">
                <Card>
                  <CardMedia
                    sx={{ height: 240 }}
                    image={client2}
                    title="client"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      APEXONE
                    </Typography>
                    <p className="text-xs">
                      ApexOne turned to Ascentia Partners Wealth Management for
                      help facilitating investor relations activities, allowing
                      the firm to raise $155 million in 11 months.
                    </p>
                  </CardContent>
                </Card>
              </div>
              <div className="col-span-2">
                <Card>
                  <CardMedia
                    sx={{ height: 240 }}
                    image={client3}
                    title="client"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      SLATE
                    </Typography>
                    <p className="text-xs">
                      Canadian-based firm’s search for an investor relations
                      management software solution led them to Ascentia Partners
                      Wealth Management.
                    </p>
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
          <div className="mt-10 mx-auto">
            <Link to="/clients">
              <button className="px-10 rounded-md py-2 bg-c-4 text-black uppercase font-medium tracking-wider">
                learn more
              </button>
            </Link>
          </div>
        </div>
      </section>

      <section className="w-full bg-c-4 md:py-20 py-36 shadow-xl ">
        <div className="mx-auto w-6/12 max-md:w-10/12 max-xl:w-8/12 max-2xl:w-9/12 bg-slate-60">
          <p className="text-gray-700 font-bold text-sm uppercase text-center">
            {" "}
            ascentia partners{" "}
          </p>
          <p className="max-md:text-2xl text-black text-5xl text-center py-4 font-semibold font-serif mx-auto">
            Where partnership drive potential
          </p>
          <p className="text-xs text-center lg:w-5/12 mx-auto">
            Partnership Enablement allows GPs and LPs to connect, communicate,
            and collaborate throughout the entire private investment
            lifecycle—all based on a single source of truth.
          </p>
          <div className="mt-10 w-full flex justify-center items-center">
            <Link to="/contact">
              <button className="px-10 rounded-md py-2 text-c-4 bg-black uppercase font-medium tracking-wider">
                contact us
              </button>
            </Link>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Home;
