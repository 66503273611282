import { React, useState } from "react";

const FormInput = (props) => {
  const { errorMessage, name, type, ...rest } = props;
  const [focused, setFocused] = useState(false);

  const handleFocus = (e) => {
    setFocused(true);
  };

  const capitalizedName = name.charAt(0).toUpperCase() + name.slice(1);

  return (
    <div className="w-ful my-3">
      <label className="block text-sm">{capitalizedName}</label>
      <input
        {...rest}
        type={type}
        name={name} // Add the name attribute here
        placeholder={capitalizedName}
        onBlur={handleFocus}
        onFocus={() => name === "confirmPassword" && setFocused(true)}
        focused={focused.toString()}
        className="pl-5 w-64 py-3 border"
      />
      <small className="text-xs text-red-600 w-64 hidden">{errorMessage}</small>
    </div>
  );
};

export default FormInput;
