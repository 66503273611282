import React, { useState } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Helmet } from "react-helmet";

const Contact = () => {
  const FORM_ENDPOINT =
    "https://public.herotofu.com/v1/e1f49330-e058-11ee-8980-3397320e035b";

  const [expanded, setExpanded] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    visitorName: "",
    visitorPhoneNumber: "",
    visitorEmail: "",
    visitorMessage: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const inputs = e.target.elements;
    const data = {};

    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].name) {
        data[inputs[i].name] = inputs[i].value;
      }
    }

    fetch(FORM_ENDPOINT, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Form response was not ok");
        }

        setSubmitted(true);
        setIsSubmitting(false);
        setFormData({
          visitorName: "",
          visitorPhoneNumber: "",
          visitorEmail: "",
          visitorMessage: "",
        });
      })
      .catch((err) => {
        // Submit the form manually
        e.target.submit();
      });
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Helmet>
        <title>Contact Us | Ascentia Partner Wealth</title>
        <meta
          content="investment platform, Ascentia Partner Wealth Contact Us, Contact Us, Ascentia Partner Wealth investment platform "
          name="keywords"
        />
      </Helmet>
      <Navbar />
      <div className="w-full bg-no-repeat bg-cover bg-bottom bg-luminary">
        <div className="w-full py-52 max-md:py-36">
          <div className="mx-auto w-6/12 max-md:w-11/12 max-xl:w-8/12 max-2xl:w-10/12">
            <h1 className="text-6xl text-white font-semibold">
              Build thriving partnerships
            </h1>
            <p className="text-md text-white font-semibold w-2/3 max-md:w-full max-lg:w-8/12 pt-3">
              Whether you have inquiries, potential partnerships, or are simply
              eager to explore investment possibilities, our team is ready to
              engage. Reach out to us, and let's build success together.
            </p>
          </div>
        </div>
      </div>
      <div className="py-20 mx-auto w-6/12 max-md:w-10/12 max-xl:w-7/12 max-2xl:w-8/12 grid grid-cols-2 max-md:grid-cols-1 gap-10">
        <div className="flex items-center justify-center">
          <div>
            <p className="mb-5 font-medium ">Frequently asked questions</p>
            <div>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<MdKeyboardArrowDown />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ color: "text.secondary" }}>
                    Who can I contact for assistance?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Your Ascentia Partners Wealth Management advisor should
                    always be your first point of contact for questions about
                    your accounts.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={<MdKeyboardArrowDown />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography sx={{ color: "text.secondary" }}>
                    What holdings are in Ascentia Partners Wealth Management's
                    portfolio?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    As a global investment organization, we invest in public
                    equities, private equities, bonds, real estate, public and
                    private debt, infrastructure and other areas. We regularly
                    communicate about new investments and disclose our current
                    standing investments on our website.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<MdKeyboardArrowDown />}
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                >
                  <Typography sx={{ color: "text.secondary" }}>
                    What are the principles of Ascentia Partners Wealth
                    Management compensation framework?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Our compensation program reflects our public accountability
                    and our responsibility to manage the Funds in the best
                    interests of Ascentia Partners investors and beneficiaries.
                    We align incentives to the delivery of our mandate and
                    long-term strategy, while also considering our appetite for
                    taking on different types of risks in our pursuit of
                    value-add returns.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <AccordionSummary
                  expandIcon={<MdKeyboardArrowDown />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography>
                    When will I receive my money after I request a withdrawal?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    When we receive a withdrawal request in good order, we
                    process it right away. You will receive your withdrawal by
                    Electronic Funds Transfer (EFT) within 3-5 business days. If
                    you’re not set up for EFT, we’ll write you a cheque and send
                    it by regular mail. If you would like to receive your money
                    by EFT, please provide your advisor with a pre-printed,
                    personalized void cheque which they can attach to your
                    withdrawal request.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
        {/*------------------------Contact form------------------------------*/}
        <div className="bg-c-4 py-10 px-10">
          <form
            onSubmit={handleSubmit}
            method="POST"
            className="rounded-lg px-1"
          >
            <p>Full Name</p>
            <input
              type={"text"}
              name="visitorName"
              placeholder="Full Name*"
              value={formData.visitorName}
              onChange={(e) =>
                setFormData({ ...formData, visitorName: e.target.value })
              }
              className="bg-white block my-4 border-0 outline-none rounded-md p-4 w-full md:w-full"
            />
            <div>
              <label for="phone" className="block text-sm font-medium ">
                Phone Number
              </label>
              <input
                type={"tel"}
                name="visitorPhoneNumber"
                placeholder="Phone Number"
                value={formData.visitorPhoneNumber}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    visitorPhoneNumber: e.target.value,
                  })
                }
                className="bg-white block my-2 border-0 outline-none rounded-md p-4 w-full md:w-full"
              />
            </div>
            <p>Email Address</p>
            <input
              type={"email"}
              name="visitorEmail"
              placeholder="Email Address*"
              value={formData.visitorEmail}
              onChange={(e) =>
                setFormData({ ...formData, visitorEmail: e.target.value })
              }
              className="bg-white block my-4 border-0 outline-none rounded-md p-4 w-full md:w-full"
            />
            <p>Leave A Message</p>
            <textarea
              rows={5}
              placeholder="Leave a message"
              type={"text"}
              name="visitorMessage"
              value={formData.visitorMessage}
              onChange={(e) =>
                setFormData({ ...formData, visitorMessage: e.target.value })
              }
              className="bg-white block my-4 border-0 outline-none rounded-md p-4 w-full md:w-full h-[150px]"
            />
            <button
              type="submit"
              disabled={isSubmitting}
              className="bg-white rounded-sm flex justify-center items-center mx-auto px-10 py-2 mt-8"
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
