import { React, useState } from "react";
import { IoHelpOutline } from "react-icons/io5";
import { TbLayoutDashboard } from "react-icons/tb";
import { TbPackages } from "react-icons/tb";
import Sidebar from "../components/Sidebar";

const UserProductPage = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const Menus = [
        {
          title: "Dashboard",
          icon: <TbLayoutDashboard />,
          address: "dashboard/user/:email",
        },
        { title: "Product", icon: <TbPackages />, address: "dashboard/admin/adminProduct" },
      ];


  return (
    <div>
        <Sidebar open={sidebarOpen} setOpen={setSidebarOpen} data={Menus}/>
        <div className="bg-slate-200 w-full min-h-screen">
            <div className="bg-slate-200 w-10/12 max-sm:w-10/12 max-sm:ml-16 mx-auto pb-10 h-screen">
                <div className="flex h-full items-center justify-center">
                    Coming Soon
                </div>
            </div>
        </div>
    </div>
  )
}

export default UserProductPage