import React from 'react'
import { FaFacebookSquare } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { FaTwitter } from "react-icons/fa";
import { IoLogoLinkedin } from "react-icons/io5";
import client from "../assets/images/logo.png";

function Footer() {
  return (
    <section className="w-full">
    <div className=" bg-c-4 pb-5 pt-3">
      <div className="mx-auto w-6/12 max-md:w-10/12 max-xl:w-8/12 max-2xl:w-9/12">
        <div className="flex justify-between pb-5 border-b border-b-black mb-5">
          <div>
            <img
              src={client}
              className="w-40 max-sm:w-32"
              alt="client logo"
            />
          </div>
          <div className="flex mt-3 text-black max-sm:text-xl text-2xl">
            <FaFacebookSquare className="mx-3 " />
            <AiFillInstagram />
            <FaTwitter className="mx-3 " />
            <IoLogoLinkedin />
          </div>
        </div>
        <div className="sm:text-center pt-1">
          <p className="text-xs text-black">
            Copyright © 2024 Investo, All rights reserved.
          </p>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Footer