import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { React } from "react";
import { Toaster } from "react-hot-toast";
import FormInput from "../components/FormInput";
import { generateOTP } from "../helpers/helper";
import toast from "react-hot-toast";
import { Helmet } from "react-helmet";

function ForgetPassword() {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      try {
        let otpPromise = generateOTP(values.email);
        toast.promise(otpPromise, {
          loading: "Creating...",
          success: <b>OTP has been sent to your email!</b>,
          error: <b>Could not Register.</b>,
        });

        navigate(`/recovery/${values.email}`);
      } catch (error) {
        toast.error(error.message || "An error occurred while generating OTP!");
      }
    },
  });

  return (
    <>
      <Helmet>
        <title>Forget Password | Ascentia Partner Wealth</title>
      </Helmet>
      <div className="bg-c-4 max-sm:py-10 sm:h-screen w-screen min-h-screen flex items-center justify-center">
        <Toaster position="top-center"></Toaster>

        <div className=" bg-white sm:px-20 sm:py-10 px-10 py-5 rounded-lg">
          <p className="text-c-1 text-3xl font-semibold text-center">
            Forget Password
          </p>

          <form onSubmit={formik.handleSubmit}>
            <FormInput
              type="email"
              name="email"
              {...formik.getFieldProps("email")}
            />
            <button className="bg-c-1 text-c-4 w-full py-2" type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default ForgetPassword;
