import { useFormik } from "formik";
import { React } from "react";
import toast, { Toaster } from "react-hot-toast";
import FormInput from "../components/FormInput";
import { useParams, useNavigate } from "react-router-dom";
import { verifyOTP } from "../helpers/helper";
import { Helmet } from "react-helmet";

function Recovery() {
  const { email } = useParams();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      try {
        let { status } = await verifyOTP({ email, code: values.otp });
        if (status === 201) {
          toast.success("Verify Successfully!");
          navigate(`/reset/${email}`);
        }
      } catch (error) {
        return toast.error("Wrong OTP! Check email again!");
      }
    },
  });

  return (
    <>
      <Helmet>
        <title>Recovery | Ascentia Partner Wealth</title>
      </Helmet>
      <div className="bg-teal-400 max-sm:py-10 sm:h-screen w-screen min-h-screen flex items-center justify-center">
        <Toaster position="top-center"></Toaster>

        <div className=" bg-white sm:px-20 sm:py-10 px-10 py-5 rounded-lg">
          <p className="text-teal-400 text-3xl font-semibold text-center">
            Enter 6 digit OTP
          </p>

          <form onSubmit={formik.handleSubmit}>
            <FormInput
              type="text"
              name="Otp"
              {...formik.getFieldProps("otp")}
            />
            <button className="bg-teal-400 w-full py-2" type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default Recovery;
