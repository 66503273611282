import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import ModalComponent from "./AdminModal";
import SearchInput from "./SearchInput";

const TableComponent = ({ rowData, columnData, tableHeader }) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchResults, setSearchResults] = useState(rowData);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRowClick = (row) => {
    setSelectedRow(row);
  };

  const handleSearch = (query) => {
    const filteredData = rowData.filter((row) => {
      return row.email && row.email.toLowerCase().includes(query.toLowerCase());
    });
    setSearchResults(filteredData);
    setPage(0); // Reset to the first page when search changes
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page when rows per page changes
  };

  const visibleRows = searchResults.slice(
    page * rowsPerPage,
    (page + 1) * rowsPerPage
  );

  return (
    <>
      <div className="w-full flex justify-between sm:px-10 max-sm:pl-7 max-sm:pr-3">
        <p className="text-xl font-bold">{tableHeader}</p>
        <SearchInput onSearch={handleSearch} />
      </div>
      <div className="px-10 max-sm:px-5">
        <Table>
          <TableHead>
            {columnData.map((row, index) => (
              <TableRow key={index} className="">
                <TableCell>
                  <p className="text-sm font-semibold w-20">No.</p>
                </TableCell>
                <TableCell>
                  <p className="text-sm font-semibold">{row.name}</p>
                </TableCell>
                <TableCell>
                  <p className="text-sm font-semibold">{row.status}</p>
                </TableCell>
                <TableCell>
                  <p className="text-sm font-semibold">{row.amount}</p>
                </TableCell>
                <TableCell>
                  <p className="text-sm font-semibold">Profit Margin</p>
                </TableCell>
                <TableCell>
                  <p className="text-sm font-semibold">Total</p>
                </TableCell>
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {visibleRows.map((row, index) => (
              <TableRow key={index}>
                <TableCell onClick={() => handleRowClick(row)}>
                  {index + 1}
                </TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.status}</TableCell>
                <TableCell>{row.deposit}</TableCell>
                <TableCell>{row.total}</TableCell>
                <TableCell>{row.total + row.deposit}</TableCell>
                {/* Add more TableCell components for other columns */}
              </TableRow>
            ))}
          </TableBody>
          <div className="w-full">
            <TablePagination
              rowsPerPageOptions={[10, 25, 30]}
              component="div"
              count={searchResults.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
          {selectedRow && (
            <ModalComponent
              open={!!selectedRow}
              onClose={() => setSelectedRow(null)}
              rowData={selectedRow}
            />
          )}
        </Table>
      </div>
    </>
  );
};

export default TableComponent;
