import { React } from "react";
import FormInput from "../components/FormInput";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { passwordValidate, registerUser } from "../helpers/helper";
import toast, { Toaster } from "react-hot-toast";
import { Helmet } from "react-helmet";

function RegisterPage() {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
      phoneNumber: "",
      jobTitle: "",
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      if (values.password === values.confirmPassword) {
        const errors = await passwordValidate(values); // Validate the password
        if (Object.keys(errors).length > 0) {
          // If there are validation errors, display them and prevent registration
          Object.values(errors).forEach((error) => toast.error(error));
          return;
        }
        let registerPromise = registerUser({
          email: values.email,
          password: values.password,
          phoneNumber: values.phoneNumber,
          jobTitle: values.jobTitle,
        });
        toast.promise(registerPromise, {
          loading: "Creating...",
          success: <b>Register Successfully...!</b>,
          error: <b>Could not Register.</b>,
        });

        registerPromise.then(function () {
          navigate("/login");
        });
      } else {
        return toast.error("Password Don't Match...!");
      }
    },
  });

  return (
    <>
      <Helmet>
        <title>Register | Ascentia Partner Wealth</title>
        <meta
          content="investment platform, Ascentia Partner Wealth Sign up, Register, Sign up, Ascentia Partner Wealth investment platform "
          name="keywords"
        />
      </Helmet>
      <div className="bg-c-4 max-sm:pt-10 sm:h-screen min-h-screen w-screen flex items-center justify-center">
        <Toaster position="top-center"></Toaster>

        <div className=" bg-white sm:px-20 sm:py-10 px-10 py-5 rounded-lg">
          <div className="flex">
            <p className="text-c-4 text-3xl font-semibold text-center">
              Register
            </p>
            <span className="text-c-4 px-4 text-3xl font-semibold">|</span>
            <Link to="/login">
              <p className="text-c-1 text-3xl font-semibold text-center">
                Login
              </p>
            </Link>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <FormInput
              type="email"
              name="email"
              {...formik.getFieldProps("email")}
            />
            <FormInput
              type="text"
              name="job Title"
              {...formik.getFieldProps("jobTitle")}
            />
            <FormInput
              type="text"
              name="phone Number"
              {...formik.getFieldProps("phoneNumber")}
            />
            <FormInput
              type="password"
              name="password"
              {...formik.getFieldProps("password")}
            />
            <FormInput
              type="password"
              name="Confirm Password"
              {...formik.getFieldProps("confirmPassword")}
            />
            <button type="submit" className="bg-c-1 text-c-4 w-full py-2 ">
              Submit
            </button>
            <div className="flex py-3">
              <p className="text-xs text-black mr-1">Already own an Account?</p>
              <Link to="/login">
                <p className="text-xs text-blue-600 underline">Login</p>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default RegisterPage;
