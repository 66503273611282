import React from "react";
import Home from "./pages/Home";
import RegisterPage from "./pages/RegisterPage";
import LoginPage from "./pages/LoginPage";
import AdminDashboardPage from "./pages/AdminDashboardPage";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { AuthorizeUser } from "./middleware/auth";
import ForgetPassword from "./pages/ForgetPassword";
import Recovery from "./pages/Recovery";
import Reset from "./pages/Reset";
import About from "./pages/About";
import Clients from "./pages/Clients";
import Contact from "./pages/ContactUs";
import UserPage from "./pages/UserPage";
import UserProductPage from "./pages/UserProduct";
import AdminProduct from "./pages/AdminProduct";
import { Helmet } from "react-helmet";

// import Product from

function App() {
  /* root routes */
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home></Home>,
    },
    {
      path: "/register",
      element: <RegisterPage></RegisterPage>,
    },
    {
      path: "/about",
      element: <About></About>,
    },
    {
      path: "/clients",
      element: <Clients></Clients>,
    },
    {
      path: "/contact",
      element: <Contact></Contact>,
    },
    {
      path: "/login",
      element: <LoginPage></LoginPage>,
    },
    {
      path: "/dashboard/user/:email/userProduct",
      element: <UserProductPage></UserProductPage>,
    },
    {
      path: "/dashboard/admin/adminProduct",
      element: <AdminProduct></AdminProduct>,
    },
    {
      path: "/dashboard/admin",
      element: (
        <AuthorizeUser>
          <AdminDashboardPage></AdminDashboardPage>
        </AuthorizeUser>
      ),
    },
    {
      path: "/recovery/:email",
      element: <Recovery></Recovery>,
    },
    {
      path: "/reset/:email",
      element: <Reset></Reset>,
    },
    {
      path: "/forget-password",
      element: <ForgetPassword></ForgetPassword>,
    },
    {
      path: "/dashboard/user/:email",
      element: <UserPage></UserPage>,
    },
  ]);
  return (
    <React.StrictMode>
      <Helmet>
        <meta
          name="description"
          content="Ascentia Partners Wealth Management is a diverse conglomerate
              founded on solid values, backed by years of investment experience
              by a series of partnered investors from the USA, Canada and all
              around the world coming together to achieve a simple goal To
              Invest & Succeed."
        />
        <meta
          content="investment platform, Wealth Management, investors, financial empowerment in Canada, powerful mantra, coalition of
                  investors from the USA, Canada, and beyond, secure and reliable
                    platform for your financial success"
          name="keywords"
        />
      </Helmet>
      <RouterProvider router={router}></RouterProvider>
    </React.StrictMode>
  );
}

export default App;
