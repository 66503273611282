// ModalComponent.js
import React, { useState } from "react";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { IoClose } from "react-icons/io5";
import { payment, updateUser } from "../helpers/helper";
import toast from "react-hot-toast";

const ModalComponent = ({ open, onClose, rowData }) => {
  const [selectedDataset, setSelectedDataset] = useState(null);

  // Form fields for dataset 1
  const [deposit, setDeposit] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [status, setStatus] = useState("active");

  // Form fields for dataset 2
  const [orderAmount, setOrderAmount] = useState("");
  const [orderType, setOrderType] = useState("");

  const handleDepositChange = (e) => {
    setDeposit(e.target.value);
  };

  const handleTotalAmountChange = (e) => {
    setTotalAmount(e.target.value);
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleOrderAmountChange = (e) => {
    setOrderAmount(e.target.value);
  };

  const handleOrderTypeChange = (e) => {
    setOrderType(e.target.value);
  };

  const handleSubmitUserData = () => {
    try {
      let updateData = { status };

      if (deposit) {
        updateData.deposit = deposit;
      }
      if (totalAmount) {
        updateData.total = totalAmount;
      }

      let updatePromise = updateUser(rowData._id, updateData);

      toast.promise(updatePromise, {
        loading: "Updating...",
        success: <b>Updated Successfully...!</b>,
        error: <b>Couldn't update</b>,
      });
      onClose();
    } catch (error) {
      return toast.error("Check Your network");
    }
  };

  const handleSubmitPayment = () => {
    try {
      let paymentPromise = payment(rowData._id, {
        amount: orderAmount,
        type: orderType,
      });

      toast.promise(paymentPromise, {
        loading: "Updating...",
        success: <b>Updated Successfully...!</b>,
        error: <b>Couldn't update</b>,
      });
      onClose();
    } catch (error) {
      return toast.error("Check Your network");
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <IconButton
        edge="end"
        color="inherit"
        onClick={onClose}
        aria-label="close"
      >
        <div className="w-full">
          <span className="float-right pr-5">
            <IoClose />
          </span>
        </div>
      </IconButton>
      <DialogTitle>
        <h2 className="text-xl font-bold mb-4">{rowData.email}'s Details</h2>
      </DialogTitle>
      <DialogContent>
        <div className="modal-content">
          {/* Dataset Selection */}
          <div className=" w-full flex justify-between mb-5 mx-auto">
            <button
              onClick={() => setSelectedDataset("dataset1")}
              className="bg-c-4 px-5 py-3 rounded-md"
            >
              User Data
            </button>
            <button
              onClick={() => setSelectedDataset("dataset2")}
              className="bg-c-4 px-5 py-3 rounded-md border"
            >
              Payment
            </button>
          </div>

          {/* Dataset 1 Form */}
          {selectedDataset === "dataset1" && (
            <>
              {/* Deposit Input */}
              <div className="mb-4">
                <label
                  htmlFor="deposit"
                  className="block text-sm font-medium text-gray-600"
                >
                  Deposit
                </label>
                <input
                  type="text"
                  id="deposit"
                  value={deposit}
                  onChange={handleDepositChange}
                  className="mt-1 p-2 border w-full"
                />
              </div>

              {/* Total Amount Input */}
              <div className="mb-4">
                <label
                  htmlFor="totalAmount"
                  className="block text-sm font-medium text-gray-600"
                >
                  Profit Margin
                </label>
                <input
                  type="text"
                  id="totalAmount"
                  value={totalAmount}
                  onChange={handleTotalAmountChange}
                  className="mt-1 p-2 border w-full"
                />
              </div>

              {/* Status Select */}
              <div className="mb-4">
                <label
                  htmlFor="status"
                  className="block text-sm font-medium text-gray-600"
                >
                  Status
                </label>
                <select
                  id="status"
                  value={status}
                  onChange={handleStatusChange}
                  className="mt-1 p-2 border w-full"
                >
                  <option value="active">Active</option>
                  <option value="pending">Pending</option>
                  <option value="confirmed">Confirmed</option>
                </select>
              </div>
              {/* Submit Button */}
              <div className="w-full flex justify-center">
                <button
                  onClick={handleSubmitUserData}
                  className="bg-c-4 px-5 p-2 rounded"
                >
                  Submit
                </button>
              </div>
            </>
          )}

          {/* Dataset 2 Form */}
          {selectedDataset === "dataset2" && (
            <>
              {/* Order Amount */}
              <div className="mb-4">
                <label
                  htmlFor="Amount"
                  className="block text-sm font-medium text-gray-600"
                >
                  Amount
                </label>
                <input
                  type="text"
                  id="orderAmount"
                  value={orderAmount}
                  onChange={handleOrderAmountChange}
                  className="mt-1 p-2 border w-full"
                />
              </div>

              {/* Order Type */}
              <div className="mb-4">
                <label
                  htmlFor="selectField"
                  className="block text-sm font-medium text-gray-600"
                >
                  Select Field
                </label>
                <select
                  id="selectField"
                  value={orderType}
                  onChange={handleOrderTypeChange}
                  className="mt-1 p-2 border w-full"
                >
                  <option value="deposit">Deposit</option>
                  <option value="withdrawal">Withdrawal</option>
                </select>
              </div>
              {/* Submit Button */}
              <div className="w-full flex justify-center">
                <button
                  onClick={handleSubmitPayment}
                  className="bg-c-4 px-5 p-2 rounded"
                >
                  Submit
                </button>
              </div>
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ModalComponent;
